import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import PaginaInicial from './PaginaInicial';
import PrivateRoute from './ProtectedRoute'; // Importa a rota protegida
import Mensagens from './Mensagens';
import ZapManager from './ZapManager';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route 
                    path="/pagina-inicial" 
                    element={
                        <PrivateRoute>
                            <PaginaInicial />
                        </PrivateRoute>
                    } 
                />
                <Route 
                    path="/mensagens" 
                    element={
                        <PrivateRoute>
                            <Mensagens />
                        </PrivateRoute>
                    } 
                />

                <Route 
                    path="/zap-manager" 
                    element={
                        <PrivateRoute>
                            <ZapManager />
                        </PrivateRoute>
                    } 
                />
            </Routes>
        </Router>
    );
}

export default App;
