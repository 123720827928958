import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Grid, Menu, MenuItem, TextField, Box, Modal } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout'; // Ícone de logout
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Ícone de calendário
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Ícone de dinheiro
import CloseIcon from '@mui/icons-material/Close'; // Ícone de fechar
import MessageIcon from '@mui/icons-material/Message'; // Ícone de mensagens
import Person3Icon from '@mui/icons-material/Person3'; //Icone lead
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate } from 'react-router-dom';

function TopMenu({ onDateChange }) {
    const [greeting, setGreeting] = useState('');
    const [consultorName, setConsultorName] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [timeRemaining, setTimeRemaining] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [iframeOpen, setIframeOpen] = useState(false); // Estado do modal para o iframe
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    // Função para definir a saudação de acordo com o horário
    useEffect(() => {
        const currentHour = new Date().getHours();
        const username = localStorage.getItem('username') || 'Consultor';

        let greetingMessage = '';
        if (currentHour < 12) {
            greetingMessage = 'Bom dia';
        } else if (currentHour < 18) {
            greetingMessage = 'Boa tarde';
        } else {
            greetingMessage = 'Boa noite';
        }

        setGreeting(greetingMessage);
        setConsultorName(username);
    }, []);

    // Função para atualizar a hora atual e o tempo restante até o final do expediente
    useEffect(() => {
        const updateCurrentTime = () => {
            const now = new Date();
            const formattedTime = now.toLocaleTimeString('pt-BR', {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

            // Definir o horário de saída da empresa (18:00)
            const endOfDay = new Date();
            endOfDay.setHours(18, 0, 0, 0); // 18:00:00

            const timeDiff = endOfDay - now;
            const hoursRemaining = Math.floor(timeDiff / (1000 * 60 * 60));
            const minutesRemaining = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

            const timeRemainingMessage =
                timeDiff > 0
                    ? `${hoursRemaining}h ${minutesRemaining}min restantes para sair`
                    : 'Expediente encerrado';

            setCurrentTime(formattedTime);
            setTimeRemaining(timeRemainingMessage);
        };

        // Atualiza a cada segundo
        const timer = setInterval(updateCurrentTime, 1000);

        return () => clearInterval(timer);
    }, []);

    // Função de logout
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        navigate('/'); // Redireciona para a página de login
    };

    // Função para abrir a página de mensagens
    const handleMessagesClick = () => {
        navigate('/mensagens');
    };

    // Função para abrir a página de mensagens
    const handlePaginaInicialClick = () => {
        navigate('/pagina-inicial');
    };

    const handleWhatsAppClick = () => {
        navigate('/zap-manager');
    };

    // Funções para abrir e fechar o menu de seleção de data
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // Funções para abrir e fechar o modal com o iframe
    const handleIframeOpen = () => setIframeOpen(true);
    const handleIframeClose = () => setIframeOpen(false);

    // Função para manipular a mudança de data no DatePicker
    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
        onDateChange(newDate); // Callback para atualizar a página inicial com a nova data
        handleMenuClose(); // Fecha o menu após selecionar a data
    };

    return (
        <AppBar
            position=""
            sx={{ 
                background: 'linear-gradient(270deg, #1f2a48, #3043ec, #4b5563)', 
                backgroundSize: '400% 400%', 
                animation: 'smoothGradient 12s ease infinite',
                color: '#fff', 
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', 
                padding: '16px 24px', 
                zIndex: 1201, 
                width: '90%', 
                margin: '30px auto', 
                borderRadius: '24px',
                backdropFilter: 'blur(5px)', 
                '@keyframes smoothGradient': {
                    '0%': { backgroundPosition: '0% 50%' },
                    '50%': { backgroundPosition: '100% 50%' },
                    '100%': { backgroundPosition: '0% 50%' }
                },
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 6px 40px rgba(0, 0, 0, 0.3)', 
                    transform: 'scale(1.01)', 
                }
            }}
        >
            <Toolbar sx={{ display: 'flex', justifyContent: '' }}>
                <Grid container spacing={2} alignItems="center">
                    {/* Saudação e nome do consultor */}
                    <Grid item>
                        <Typography variant="h5" sx={{ color: '#fff', fontWeight: '600' }}>
                            {greeting}, {consultorName}!
                        </Typography>
                    </Grid>
                    {/* Hora atual */}
                    <Grid item>
                        <Typography variant="body1" sx={{ color: '#fff', backgroundColor: '#4b5563', padding: '8px', borderRadius: '20px' }}>
                            Hora atual: {currentTime}
                        </Typography>
                    </Grid>
                    {/* Tempo restante */}
                    <Grid item>
                        <Typography variant="body2" sx={{ color: '#fff', backgroundColor: '#4b5563', padding: '8px', borderRadius: '20px' }}>
                            {timeRemaining}
                        </Typography>
                    </Grid>
                </Grid>

                {/* Botão expansível para selecionar a data */}
                <IconButton
                    onClick={handleMenuOpen}
                    sx={{
                        backgroundColor: '#fff',
                        color: '#3043ec',
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px',
                        margin: '10px',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                    }}
                >
                    <CalendarTodayIcon />
                </IconButton>

                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Selecione a Data"
                                value={selectedDate}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </MenuItem>
                </Menu>

                {/* Ícone de dinheiro que abre o modal */}
                <IconButton
                    onClick={handleIframeOpen}
                    sx={{
                        backgroundColor: '#fff',
                        color: '#3043ec',
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px',
                        margin: '10px',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                    }}
                >
                    <AttachMoneyIcon />
                </IconButton>

                {/* Ícone de mensagens */}
                <IconButton
                    onClick={handleMessagesClick}
                    sx={{
                        backgroundColor: '#fff',
                        color: '#3043ec',
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px',
                        margin: '10px',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                    }}
                >
                    <MessageIcon />
                </IconButton>

                {/* Ícone de Lead */}
                <IconButton
                    onClick={handlePaginaInicialClick}
                    sx={{
                        backgroundColor: '#fff',
                        color: '#3043ec',
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px',
                        margin: '10px',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                    }}
                >
                    <Person3Icon />
                </IconButton>

                                {/* Ícone de Lead */}
                                <IconButton
                    onClick={handleWhatsAppClick}
                    sx={{
                        backgroundColor: '#fff',
                        color: '#3043ec',
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px',
                        margin: '10px',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                    }}
                >
                    <WhatsAppIcon />
                </IconButton>

                {/* Modal que contém o iframe */}
                <Modal
                    open={iframeOpen}
                    onClose={handleIframeClose}
                    aria-labelledby="iframe-modal-title"
                    aria-describedby="iframe-modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: '40px' }}>
                        <IconButton
                            onClick={handleIframeClose}
                            sx={{
                                backgroundColor: '#fff',
                                color: '#000',
                                borderRadius: '50%',
                                width: '36px',
                                height: '36px',
                                position: 'absolute',
                                top: 16,
                                right: 16,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <iframe
                            src="https://botapi.inthorus.digital/novo-teste-98bqt3l"
                            style={{ border: 'none', width: '100%', height: '600px' }}
                            title="External Iframe"
                        />
                    </Box>
                </Modal>

                {/* Ícone de logout */}
                <IconButton
                    color="secondary"
                    onClick={handleLogout}
                    sx={{
                        backgroundColor: '#fff',
                        color: '#3043ec',
                        borderRadius: '50%',
                        width: '48px',
                        height: '48px',
                        margin: '10px',
                        '&:hover': {
                            backgroundColor: '#e0e0e0',
                        },
                    }}
                >
                    <LogoutIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}

export default TopMenu;
