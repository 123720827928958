import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, TextField, Button, Typography, Grid, Container } from '@mui/material';
import TopMenu from './TopMenu'; // Certifique-se que o TopMenu está importado corretamente

function Mensagens() {
  const [mensagens, setMensagens] = useState({
    msg1: '',
    msg2: '',
    msg3: '',
    msg4: '',
    msg5: ''
  });
  const [editMode, setEditMode] = useState(false); // Controla o modo de edição
  const [consultorName, setConsultorName] = useState('');

  // Dados do Lead (simulação de placeholders)
  const placeholders = {
    '{{nome}}': 'Nome do Cliente',
    '{{processo}}': 'Número do Processo',
    '{{cpf}}': 'CPF',
    '{{email}}': 'Email',
    '{{valorEntrada}}': 'Valor de Entrada',
    '{{marcaModelo}}': 'Marca/Modelo do Veículo',
    '{{anoVeiculo}}': 'Ano do Veículo',
    '{{corVeiculo}}': 'Cor do Veículo',
    '{{chassi}}': 'Chassi',
    '{{renavam}}': 'Renavam',
    '{{placa}}': 'Placa'
  };

  useEffect(() => {
    // Obtendo o nome do consultor logado do localStorage
    const storedConsultorName = localStorage.getItem('username') || 'Consultor';
    setConsultorName(storedConsultorName);

    const fetchMensagens = async () => {
      try {
        const token = localStorage.getItem('token'); // Obtém o token

        const response = await axios.get('https://text.wh.tec.br/mensagens', {
          headers: {
            Authorization: token,
            'consultor-name': storedConsultorName
          }
        });
        
        setMensagens(response.data); // Salva as mensagens retornadas no estado
      } catch (error) {
        console.error('Erro ao buscar mensagens:', error);
      }
    };

    fetchMensagens();
  }, []);

  // Função para lidar com a edição das mensagens
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMensagens((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  // Função para salvar as mensagens editadas
  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token'); // Obtém o token
      await axios.post('https://text.wh.tec.br/mensagens', mensagens, {
        headers: {
          Authorization: token,
          'consultor-name': consultorName
        }
      });
      alert('Mensagens atualizadas com sucesso!');
      setEditMode(false); // Sai do modo de edição após salvar
    } catch (error) {
      console.error('Erro ao salvar mensagens:', error);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #1e1e30, #3b3b51, #60607a)',
                backgroundSize: '200% 200%',
                animation: 'gradientShift 20s ease infinite',
                '@keyframes gradientShift': {
                    '0%': { backgroundPosition: '0% 0%' },
                    '50%': { backgroundPosition: '100% 100%' },
                    '100%': { backgroundPosition: '0% 0%' }
                },
                boxShadow: 'inset 0 0 80px rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(4px)',
      }}
    >
      {/* Exibe o TopMenu */}
      <TopMenu />

      {/* Corpo da página de mensagens */}
      <Container maxWidth="">
        <Box
          sx={{
            margin: '40px 20px',
            backgroundColor: '#414141', // Fundo escuro
            borderRadius: '12px',
            padding: '30px',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
            marginTop: '20px',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: '#fff',
              textAlign: 'center',
              marginBottom: '20px',
              fontWeight: 'bold',
            }}
          >
           🎨 Personalize suas mensagens aqui, {consultorName}, do seu jeito! ✨😊
          </Typography>

          {/* Legenda dos placeholders */}
          <Typography variant="body1" sx={{ color: '#fff', marginBottom: '20px' }}>
            <strong>Placeholders disponíveis:</strong>
            <ul>
              {Object.entries(placeholders).map(([placeholder, description]) => (
                <li key={placeholder} style={{ marginBottom: '8px' }}>
                  <strong>{placeholder}</strong>: {description}
                </li>
              ))}
            </ul>
            <Typography variant="body2" sx={{ color: '#e0e0e0' }}>
              Use os placeholders acima nas suas mensagens para inserir informações automaticamente.
            </Typography>
          </Typography>

          <Grid container spacing={2}>
            {['msg1', 'msg2', 'msg3', 'msg4', 'msg5'].map((msg, index) => (
              <Grid item xs={12} key={msg}>
                <TextField
                  name={msg}
                  label={`Mensagem ${index + 1}`}
                  value={mensagens[msg]}
                  onChange={handleInputChange}
                  fullWidth
                  disabled={!editMode} // Desabilita os campos se não estiver em modo de edição
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '8px',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            ))}
          </Grid>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '20px',
              marginTop: '30px',
            }}
          >
            {!editMode ? (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#fb6637',
                  color: '#fff',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  '&:hover': { backgroundColor: '#f53d14' },
                }}
                onClick={() => setEditMode(true)}
              >
                Editar Mensagens
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#34c759',
                    color: '#fff',
                    padding: '10px 20px',
                    borderRadius: '8px',
                    '&:hover': { backgroundColor: '#28a745' },
                  }}
                  onClick={handleSave}
                >
                  Salvar
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#ff3b30',
                    color: '#fff',
                    padding: '10px 20px',
                    borderRadius: '8px',
                    '&:hover': { backgroundColor: '#e01e0f' },
                  }}
                  onClick={() => setEditMode(false)}
                >
                  Cancelar
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Container>
      {/* Footer */}
      <Box sx={{ padding: '10px', textAlign: 'center', color: '#fff', mt: 'auto' }}>
                    <Typography variant="body2">
                        Created by W&H - Group
                    </Typography>
                </Box>
    </Box>
  );
}

export default Mensagens;
