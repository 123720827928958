import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
    IconButton,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Box,
    Avatar,
    Tooltip,
    Badge,
    Divider,
    Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import UploadIcon from '@mui/icons-material/Upload';
import ChatIcon from '@mui/icons-material/Chat';
import axios from 'axios';

// Estilização personalizada para o Card
const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '20px',
    backgroundColor: '#fff',
    color: '#333',
    padding: '24px',
    marginBottom: '24px',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
    transition: 'transform 0.3s, box-shadow 0.3s',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 8px 30px rgba(0,0,0,0.15)',
    },
}));

// Estilização personalizada para o Avatar
const LeadAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: '#1976d2',
    width: theme.spacing(7),
    height: theme.spacing(7),
    fontSize: '2rem',
}));

function LeadCard({ lead, onViewDetails, onStatusChange, disabled }) {
    // Estados
    const [status, setStatus] = useState('');
    const [timeLeft, setTimeLeft] = useState(null);
    const [showAdditionalPhones, setShowAdditionalPhones] = useState(false);
    const [mensagens, setMensagens] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [novaMensagem, setNovaMensagem] = useState('');
    const [hasNewMessage, setHasNewMessage] = useState({});
    const [ultimaMensagem, setUltimaMensagem] = useState({});
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
    const [uploadingAudio, setUploadingAudio] = useState(false);
    const [audioFile, setAudioFile] = useState(null);

    const messagesEndRef = useRef(null);
    const currentUser = localStorage.getItem('username');

    // Função para rolar para a última mensagem
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // Função para lidar com a expiração do status 'negociando'
    const handleExpiration = useCallback(async () => {
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://apil2.wh.tec.br/base_sp/clear', {
                leadId: lead.id
            }, {
                headers: {
                    'Authorization': token
                }
            });

            if (response.status === 200) {
                onStatusChange(lead.id, '');
            } else {
                console.error('Falha ao atualizar após expiração:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao tentar atualizar após expiração:', error);
        }
    }, [lead.id, onStatusChange]);

    // Função para personalizar a mensagem substituindo placeholders
    const personalizeMessage = (message) => {
        return message
            .replace('{{nome}}', lead.nome_cliente)
            .replace('{{processo}}', lead.processo)
            .replace('{{cpf}}', lead.cpf)
            .replace('{{email}}', lead.email)
            .replace('{{valorEntrada}}', lead.valor_entrada)
            .replace('{{marcaModelo}}', lead.marca_modelo)
            .replace('{{anoVeiculo}}', lead.ano_veiculo)
            .replace('{{corVeiculo}}', lead.cor_veiculo)
            .replace('{{chassi}}', lead.chassi)
            .replace('{{renavam}}', lead.renavam)
            .replace('{{placa}}', lead.placa);
    };

    // Função para obter uma mensagem aleatória, sem repetir a última
    const getRandomMessage = (phone) => {
        const allMessages = mensagens.filter(msg => msg.messageType === 'text' && msg.phone === phone);
        if (allMessages.length === 0) return '';

        let novaMsg = '';
        do {
            const randomIndex = Math.floor(Math.random() * allMessages.length);
            novaMsg = allMessages[randomIndex].mensagem;
        } while (novaMsg === ultimaMensagem[phone] && allMessages.length > 1);

        setUltimaMensagem(prev => ({ ...prev, [phone]: novaMsg }));
        return novaMsg;
    };

    // Função para enviar mensagem via WhatsApp (texto)
    const sendWhatsAppMessage = async (phone) => {
        const telefoneComDDI = `55${phone}`;
        const mensagemAleatoria = personalizeMessage(getRandomMessage(phone));

        try {
            const response = await axios.post('https://send.wh.tec.br/send-message', {
                number: telefoneComDDI,
                message: mensagemAleatoria
            });

            if (response.status === 200) {
                console.log('Mensagem enviada com sucesso.');
                handleStatusChange('enviado');
            }
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error);
        }
    };

    // Função para enviar mensagem para telefones adicionais (texto)
    const sendWhatsAppMessageTo = async (phone) => {
        const telefoneComDDI = `55${phone}`;
        const mensagemAleatoria = personalizeMessage(getRandomMessage(phone));

        try {
            const response = await axios.post('https://send.wh.tec.br/send-message', {
                number: telefoneComDDI,
                message: mensagemAleatoria
            });

            if (response.status === 200) {
                console.log(`Mensagem enviada com sucesso para ${phone}.`);
            }
        } catch (error) {
            console.error(`Erro ao enviar mensagem para ${phone}:`, error);
        }
    };

    // Função para alterar o status da lead
    const handleStatusChange = async (newStatus) => {
        setStatus(newStatus);

        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://apil2.wh.tec.br/base_sp/status', {
                leadId: lead.id,
                status: newStatus
            }, {
                headers: {
                    'Authorization': token
                }
            });

            if (response.status === 200) {
                onStatusChange(lead.id, newStatus);
            } else {
                console.error('Falha ao atualizar status:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao tentar atualizar status:', error);
        }
    };

    // Função para enviar uma nova mensagem digitada pelo usuário (texto)
    const handleSendNewMessage = async () => {
        if (!selectedPhoneNumber) return;

        const telefoneComDDI = `55${selectedPhoneNumber}`;

        try {
            const response = await axios.post('https://send.wh.tec.br/send-message', {
                number: telefoneComDDI,
                message: novaMensagem
            });

            if (response.status === 200) {
                console.log('Nova mensagem enviada com sucesso.');
                setMensagens(prev => [...prev, { mensagem: novaMensagem, enviadaPeloUsuario: true, timestamp: new Date().toISOString(), messageType: 'text', phone: selectedPhoneNumber }]);
                setNovaMensagem('');
                setHasNewMessage(prev => ({ ...prev, [selectedPhoneNumber]: false }));
                scrollToBottom();
            }
        } catch (error) {
            console.error('Erro ao enviar a nova mensagem:', error);
        }
    };

    // Função para enviar uma nova mensagem de áudio
    const handleAudioUpload = async () => {
        if (!audioFile) {
            alert('Por favor, selecione um arquivo de áudio para enviar.');
            return;
        }

        if (!selectedPhoneNumber) {
            alert('Por favor, selecione um número de telefone para enviar o áudio.');
            return;
        }

        const formData = new FormData();
        formData.append('audio', audioFile);
        formData.append('telefone', selectedPhoneNumber);
        formData.append('ptt', false);
        formData.append('seconds', 0);
        formData.append('pushName', currentUser);
        formData.append('owner', currentUser);

        setUploadingAudio(true);

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('https://msg.wh.tec.br/mensagens/audio', formData, {
                headers: {
                    Authorization: token,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                alert('Áudio enviado com sucesso!');
                setAudioFile(null);
                fetchMensagens(selectedPhoneNumber);
                scrollToBottom();
            }
        } catch (error) {
            console.error('Erro ao enviar áudio:', error);
            alert('Falha ao enviar o áudio.');
        } finally {
            setUploadingAudio(false);
        }
    };

    // Função para formatar o tempo restante
    const formatTimeLeft = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const days = Math.floor(totalSeconds / (60 * 60 * 24));
        const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
        const seconds = totalSeconds % 60;
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    // Função para alternar a exibição de telefones adicionais
    const toggleAdditionalPhones = () => {
        setShowAdditionalPhones(!showAdditionalPhones);
    };

    // Função para renderizar um telefone adicional com botão de ícone
    const renderPhone = (label, phone, isWhatsApp) => {
        if (!phone) return null;
        return (
            <Grid container alignItems="center" spacing={1} key={label} sx={{ marginBottom: '12px' }}>
                <Grid item xs={5}>
                    <Typography variant="subtitle1">
                        <strong>{label}:</strong> {disabled ? 'xxxx-xxxx' : phone}
                    </Typography>
                </Grid>
                <Grid item xs={7} sx={{ textAlign: 'right' }}>
                    {!disabled && isWhatsApp && (
                        <Tooltip title={`Enviar mensagem via WhatsApp para ${phone}`}>
                            <IconButton size="small" onClick={() => sendWhatsAppMessageTo(phone)} sx={{ marginRight: '8px' }}>
                                <WhatsAppIcon color="success" />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title="Ver Mensagens">
                        <IconButton
                            onClick={() => handleViewMessages(phone)}
                            disabled={disabled}
                            sx={{
                                backgroundColor: hasNewMessage[phone] ? '#ff5252' : '#1976d2',
                                '&:hover': {
                                    backgroundColor: hasNewMessage[phone] ? '#ff1744' : '#115293',
                                },
                                color: '#fff',
                                marginLeft: '4px',
                                padding: '8px',
                                borderRadius: '50%', // Formato circular
                                position: 'relative',
                            }}
                        >
                            <ChatIcon />
                            {hasNewMessage[phone] && (
                                <Badge
                                    color="secondary"
                                    variant="dot"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                    }}
                                />
                            )}
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    };

    /**
     * Função para buscar todas as mensagens (texto e áudio) a partir da nova API
     */
    const fetchMensagens = useCallback(async (phone) => {
        if (!phone) return;

        const token = localStorage.getItem('token');
        const consultorName = localStorage.getItem('username');

        try {
            const response = await axios.get(`https://msg.wh.tec.br/mensagens/55${phone}`, {
                headers: {
                    Authorization: token,
                    'consultor-name': consultorName
                }
            });

            const mensagensRecebidas = response.data.mensagens;

            if (Array.isArray(mensagensRecebidas)) {
                const mensagensFormatadas = mensagensRecebidas.map(msg => ({
                    ...msg,
                    enviadaPeloUsuario: msg.enviadaPeloUsuario === 'Sim' ? true : false,
                    phone: phone,
                    mimetype: msg.messageType === 'audioMessage' ? 'audio/mpeg' : undefined
                }));

                // Ordena as mensagens por timestamp
                mensagensFormatadas.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                setMensagens(mensagensFormatadas);
            } else {
                console.error('Erro: O formato de resposta não é um array de mensagens.');
                setMensagens([]);
            }
        } catch (error) {
            console.error('Erro ao buscar mensagens:', error);
        }
    }, []);

    /**
     * Função para abrir a modal e carregar as mensagens
     */
    const handleViewMessages = async (phone) => {
        try {
            setSelectedPhoneNumber(phone);
            await fetchMensagens(phone);
            setOpenModal(true);
            setHasNewMessage(prev => ({ ...prev, [phone]: false }));
            scrollToBottom();
        } catch (error) {
            console.error('Erro ao carregar as mensagens:', error);
            setMensagens([]);
        }
    };

    // Efeito para buscar mensagens personalizadas e lidar com o tempo de expiração
    useEffect(() => {
        setStatus(lead.status || '');

        // Se o status for 'negociando', calcular o tempo até expirar
        if (lead.status === 'negociando' && lead.expiracao) {
            const expiracaoDate = new Date(lead.expiracao);
            const now = new Date();
            const timeRemaining = expiracaoDate - now;

            if (timeRemaining > 0) {
                setTimeLeft(timeRemaining);

                const intervalId = setInterval(() => {
                    const timeLeftCalc = expiracaoDate - new Date();
                    if (timeLeftCalc <= 0) {
                        clearInterval(intervalId);
                        handleExpiration();
                    } else {
                        setTimeLeft(timeLeftCalc);
                    }
                }, 1000);

                return () => clearInterval(intervalId);
            } else {
                // Caso já tenha expirado
                handleExpiration();
            }
        }
    }, [lead.status, lead.expiracao, handleExpiration]);

    // Implementação do Polling para verificar novas mensagens
    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (!selectedPhoneNumber) return;

            try {
                const token = localStorage.getItem('token');
                const consultorName = localStorage.getItem('username');

                const response = await axios.get(`https://msg.wh.tec.br/mensagens/55${selectedPhoneNumber}`, {
                    headers: {
                        Authorization: token,
                        'consultor-name': consultorName
                    }
                });

                const mensagensRecebidas = response.data.mensagens;

                if (Array.isArray(mensagensRecebidas)) {
                    const mensagensFormatadas = mensagensRecebidas.map(msg => ({
                        ...msg,
                        enviadaPeloUsuario: msg.enviadaPeloUsuario === 'Sim' ? true : false,
                        phone: selectedPhoneNumber,
                        mimetype: msg.messageType === 'audioMessage' ? 'audio/mpeg' : undefined
                    }));

                    // Ordena as mensagens por timestamp
                    mensagensFormatadas.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

                    // Verifica se há novas mensagens
                    if (mensagensFormatadas.length > mensagens.length) {
                        setMensagens(mensagensFormatadas);

                        const newMessage = mensagensFormatadas[mensagensFormatadas.length - 1];
                        if (!openModal && !newMessage.enviadaPeloUsuario) {
                            setHasNewMessage(prev => ({ ...prev, [selectedPhoneNumber]: true }));
                        }
                    }
                }
            } catch (error) {
                console.error('Erro ao verificar novas mensagens:', error);
            }
        }, 5000); // Verifica a cada 5 segundos

        return () => clearInterval(intervalId);
    }, [selectedPhoneNumber, mensagens, openModal]);

    // Função para renderizar a modal com as mensagens
    const renderChatModal = () => (
        <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ backgroundColor: '#1976d2', color: '#fff', display: 'flex', alignItems: 'center' }}>
                <LeadAvatar>
                    {lead.nome_cliente.charAt(0).toUpperCase()}
                </LeadAvatar>
                <Box sx={{ marginLeft: '12px' }}>
                    <Typography variant="h6">Chat com {lead.nome_cliente}</Typography>
                    {selectedPhoneNumber && (
                        <Typography variant="subtitle2" sx={{ color: '#e0e0e0' }}>
                            {selectedPhoneNumber}
                        </Typography>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent dividers sx={{ padding: '24px', backgroundColor: '#f5f5f5' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '400px',
                        overflowY: 'auto',
                        padding: '8px',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        boxShadow: 'inset 0 0 10px rgba(0,0,0,0.05)'
                    }}
                >
                    {mensagens.length > 0 ? (
                        mensagens.map((msg, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: msg.enviadaPeloUsuario ? 'flex-end' : 'flex-start',
                                    marginBottom: '12px'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: msg.enviadaPeloUsuario ? 'row-reverse' : 'row',
                                        maxWidth: '80%'
                                    }}
                                >
                                    <Avatar sx={{ bgcolor: msg.enviadaPeloUsuario ? '#4caf50' : '#1976d2', margin: '0 8px' }}>
                                        {msg.enviadaPeloUsuario ? 'U' : 'L'}
                                    </Avatar>
                                    <Box
                                        sx={{
                                            padding: '12px',
                                            borderRadius: '16px',
                                            backgroundColor: msg.enviadaPeloUsuario ? '#e8f5e9' : '#e3f2fd',
                                            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {msg.messageType === 'audioMessage' ? (
                                            <audio controls>
                                                <source src={msg.mensagem} type={msg.mimetype || 'audio/mpeg'} />
                                                Seu navegador não suporta o elemento de áudio.
                                            </audio>
                                        ) : (
                                            <Typography variant="body1" color="#333">
                                                {msg.mensagem}
                                            </Typography>
                                        )}
                                        <Typography variant="caption" color="#777" sx={{ display: 'block', textAlign: msg.enviadaPeloUsuario ? 'right' : 'left', marginTop: '4px' }}>
                                            {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            Nenhuma mensagem encontrada.
                        </Typography>
                    )}
                    <div ref={messagesEndRef} />
                </Box>
            </DialogContent>
            <DialogActions sx={{ padding: '24px', backgroundColor: '#f5f5f5', display: 'flex', alignItems: 'center' }}>
                <TextField
                    variant="outlined"
                    placeholder="Digite uma mensagem"
                    fullWidth
                    value={novaMensagem}
                    onChange={(e) => setNovaMensagem(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            if (novaMensagem.trim()) {
                                handleSendNewMessage();
                            }
                        }
                    }}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: '50px',
                        marginRight: '16px'
                    }}
                />
                <IconButton
                    color="primary"
                    onClick={handleSendNewMessage}
                    disabled={!novaMensagem.trim()}
                    sx={{
                        padding: '12px',
                        backgroundColor: '#1976d2',
                        '&:hover': {
                            backgroundColor: '#115293',
                        },
                        color: '#fff'
                    }}
                >
                    <SendIcon />
                </IconButton>

                {/* Botão para enviar áudio */}
                <IconButton
                    color="primary"
                    component="label"
                    sx={{
                        padding: '12px',
                        backgroundColor: '#1976d2',
                        '&:hover': {
                            backgroundColor: '#115293',
                        },
                        color: '#fff',
                        marginLeft: '8px',
                        opacity: uploadingAudio ? 0.6 : 1,
                        pointerEvents: uploadingAudio ? 'none' : 'auto'
                    }}
                >
                    <Tooltip title="Enviar Áudio">
                        <UploadIcon />
                    </Tooltip>
                    <input
                        type="file"
                        accept="audio/*"
                        hidden
                        onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                                setAudioFile(e.target.files[0]);
                                handleAudioUpload();
                            }
                        }}
                    />
                </IconButton>
            </DialogActions>
        </Dialog>
    );

    return (
        <StyledCard>
            <CardContent>
                <Grid container spacing={3} alignItems="center">
                    {/* Avatar e Nome do Cliente */}
                    <Grid item xs={12} sm={3} md={2}>
                        <LeadAvatar>
                            {lead.nome_cliente.charAt(0).toUpperCase()}
                        </LeadAvatar>
                    </Grid>
                    <Grid item xs={12} sm={9} md={10}>
                        <Typography variant="h5" sx={{ fontWeight: '700', color: '#1976d2' }}>
                            {lead.nome_cliente}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            Processo: {lead.processo}
                        </Typography>
                        <Box sx={{ marginTop: '8px' }}>
                            <Chip label={lead.status || 'Sem Status'} color={
                                lead.status === 'enviado' ? 'primary' :
                                lead.status === 'negociando' ? 'warning' :
                                lead.status === 'nao responde' ? 'error' :
                                lead.status === 'venda realizada' ? 'success' :
                                'default'
                            } />
                            {status === 'negociando' && timeLeft && (
                                <Typography variant="body2" sx={{ color: '#ff8c00', marginTop: '4px' }}>
                                    Tempo restante: {formatTimeLeft(timeLeft)}
                                </Typography>
                            )}
                        </Box>
                    </Grid>

                    {/* Informações de Contato */}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {/* Telefone Principal */}
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" sx={{ fontWeight: '600' }}>
                                    Celular: {disabled ? 'xxxx-xxxx' : lead.telefone_principal}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                                {!disabled && lead.whatsapp && (
                                    <Tooltip title="Enviar mensagem via WhatsApp">
                                        <IconButton size="large" onClick={() => sendWhatsAppMessage(lead.telefone_principal)} color="success">
                                            <WhatsAppIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title="Ver Mensagens">
                                    <IconButton
                                        onClick={() => handleViewMessages(lead.telefone_principal)}
                                        disabled={disabled}
                                        sx={{
                                            backgroundColor: hasNewMessage[lead.telefone_principal] ? '#ff5252' : '#1976d2',
                                            '&:hover': {
                                                backgroundColor: hasNewMessage[lead.telefone_principal] ? '#ff1744' : '#115293',
                                            },
                                            color: '#fff',
                                            marginLeft: { xs: 0, sm: '8px' },
                                            padding: '8px',
                                            borderRadius: '50%', // Formato circular
                                            position: 'relative',
                                        }}
                                    >
                                        <ChatIcon />
                                        {hasNewMessage[lead.telefone_principal] && (
                                            <Badge
                                                color="secondary"
                                                variant="dot"
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                }}
                                            />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Link para Telefones Adicionais */}
                    <Grid item xs={12}>
                        <Link
                            onClick={toggleAdditionalPhones}
                            sx={{
                                cursor: 'pointer',
                                color: '#1976d2',
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            {showAdditionalPhones ? 'Ocultar Telefones' : 'Ver Mais Telefones'}
                        </Link>
                    </Grid>

                    {/* Telefones Adicionais */}
                    {showAdditionalPhones && (
                        <>
                            {renderPhone("Telefone Adicional 1", lead.telefone_adicional1, lead.telefone_adicional1_whatsapp)}
                            {renderPhone("Telefone Adicional 2", lead.telefone_adicional2, lead.telefone_adicional2_whatsapp)}
                            {renderPhone("Telefone Adicional 3", lead.telefone_adicional3, lead.telefone_adicional3_whatsapp)}
                            {renderPhone("Telefone Adicional 4", lead.telefone_adicional4, lead.telefone_adicional4_whatsapp)}
                            {renderPhone("Telefone Adicional 5", lead.telefone_adicional5, lead.telefone_adicional5_whatsapp)}
                        </>
                    )}

                    {/* Ações e Detalhes */}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Status da Lead</InputLabel>
                            <Select
                                value={status}
                                onChange={(event) => handleStatusChange(event.target.value)}
                                label="Status da Lead"
                                displayEmpty
                                disabled={disabled}
                                sx={{
                                    borderRadius: '12px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1976d2',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#115293',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: '#1976d2',
                                    },
                                }}
                            >
                                <MenuItem value="">
                                    <em>Selecione</em>
                                </MenuItem>
                                <MenuItem value="enviado">Enviado</MenuItem>
                                <MenuItem value="negociando">Negociando</MenuItem>
                                <MenuItem value="nao responde">Não Responde</MenuItem>
                                <MenuItem value="venda realizada">Venda Realizada</MenuItem>
                                <MenuItem value="resolveu com o banco">Resolveu com o banco</MenuItem>
                                <MenuItem value="fechou com outra assessoria">Fechou com outra assessoria</MenuItem>
                                <MenuItem value="nao e a pessoa">Número de telefone errado</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                        <Button
                            variant="contained"
                            startIcon={<InfoIcon />}
                            onClick={() => onViewDetails(lead)}
                            disabled={disabled}
                            sx={{
                                textTransform: 'none',
                                backgroundColor: '#ff9800',
                                '&:hover': {
                                    backgroundColor: '#fb8c00',
                                },
                            }}
                        >
                            Detalhes
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>

            {/* Modal do chat */}
            {renderChatModal()}
        </StyledCard>
    );
}

export default LeadCard;
