// Login.js
import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    Box,
    InputAdornment,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff, LockOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

// Estilização personalizada usando styled do MUI
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: '16px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    maxWidth: 400,
    width: '100%',
}));

const BackgroundGrid = styled(Grid)(({ theme }) => ({
    height: '100vh',
    background: 'linear-gradient(135deg, #3f51b5 30%, #1a237e 90%)', // Mantém a cor de fundo original
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [locationError, setLocationError] = useState('');
    const [userLocation, setUserLocation] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Captura a localização do usuário ao carregar o componente
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    setLocationError('Erro ao capturar localização. Verifique as permissões.');
                }
            );
        } else {
            setLocationError('Geolocalização não é suportada pelo navegador.');
        }
    }, []);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!userLocation) {
            setError('Localização não capturada. Tente novamente.');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const response = await fetch('https://apil.wh.tec.br/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password,
                    latitude: userLocation.latitude,
                    longitude: userLocation.longitude,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                // Supondo que o token e o username estão na resposta
                localStorage.setItem('token', data.user.token);
                localStorage.setItem('username', data.user.user);
                navigate('/pagina-inicial');
            } else {
                setError(data.message || 'Login falhou. Verifique suas credenciais.');
            }
        } catch (error) {
            setError('Ocorreu um erro ao tentar fazer login. Tente novamente.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <BackgroundGrid container>
            <StyledPaper elevation={6}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <LockOutlined color="primary" sx={{ fontSize: 50, mb: 2 }} />
                    <Typography component="h1" variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#3f51b5' }}>
                        Faça Login
                    </Typography>

                    {locationError && (
                        <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                            {locationError}
                        </Typography>
                    )}
                    {error && (
                        <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                            {error}
                        </Typography>
                    )}

                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Usuário"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockOutlined color="action" />
                                    </InputAdornment>
                                ),
                                sx: {
                                    borderRadius: '12px',
                                    backgroundColor: '#f5f5f5',
                                },
                            }}
                        />

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Senha"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockOutlined color="action" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleShowPassword}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                sx: {
                                    borderRadius: '12px',
                                    backgroundColor: '#f5f5f5',
                                },
                            }}
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={loading}
                            sx={{
                                mt: 3,
                                mb: 2,
                                padding: '12px 0',
                                borderRadius: '30px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                boxShadow: '0px 4px 15px rgba(63, 81, 181, 0.3)',
                                transition: 'background-color 0.3s ease',
                                '&:hover': {
                                    backgroundColor: '#303f9f',
                                },
                            }}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Acessar'}
                        </Button>

                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Typography variant="body2" color="primary" sx={{ cursor: 'pointer' }}>
                                    Esqueci a senha
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </StyledPaper>
        </BackgroundGrid>
    );
}

export default Login;
