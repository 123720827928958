// InstanceList.jsx
import React, { useState, useEffect, useCallback } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
    IconButton,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Box,
    Avatar,
    Tooltip,
    Snackbar,
    Alert,
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';

// Componente TopMenu
const TopMenu = () => {
    const consultantName = localStorage.getItem('username') || 'Consultor';

    return (
        <Box sx={{ flexGrow: 1, mb: 4 }}>
            <Card sx={{ backgroundColor: '#3043ec', color: '#fff', padding: 2 }}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Avatar sx={{ bgcolor: '#ff8c00', mr: 2 }}>
                            {consultantName.charAt(0).toUpperCase()}
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6">Bem-vindo, {consultantName}</Typography>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

// Componente InstanceCard
const InstanceCard = React.memo(({ instance }) => {
    const [openModal, setOpenModal] = useState(false);
    const [openProxyModal, setOpenProxyModal] = useState(false);
    const [instanceDetails, setInstanceDetails] = useState(null);
    const [qrCode, setQrCode] = useState(null);
    const [proxyConfig, setProxyConfig] = useState({
        host: '',
        port: '',
        protocol: '',
        username: '',
        password: '',
    });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleViewDetails = useCallback(async () => {
        setOpenModal(true);
        setInstanceDetails(instance);

        if (instance.status === 'connecting') {
            try {
                const response = await axios.get(
                    `https://zap.wh.tec.br/instance/connect/${instance.instanceName}?number=${instance.number}`
                );
                if (response.data.base64) {
                    setQrCode(response.data.base64);
                } else {
                    setQrCode(null);
                }
            } catch (error) {
                console.error('Erro ao buscar QR code:', error);
                setSnackbar({ open: true, message: 'Erro ao buscar QR code.', severity: 'error' });
                setQrCode(null);
            }
        }
    }, [instance]);

    const handleSetProxy = async () => {
        const config = {
            enabled: true,
            proxy: proxyConfig,
        };

        try {
            const response = await axios.post(
                `https://zap.wh.tec.br/set-proxy/${instance.instanceName}`,
                config
            );
            console.log('Proxy definido com sucesso:', response.data);
            setSnackbar({ open: true, message: 'Proxy definido com sucesso.', severity: 'success' });
            setOpenProxyModal(false);
        } catch (error) {
            console.error('Erro ao definir proxy:', error);
            setSnackbar({ open: true, message: 'Erro ao definir proxy.', severity: 'error' });
        }
    };

    const renderInstanceModal = () => (
        <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="md" fullWidth>
            <DialogTitle>Detalhes da Instância: {instance.instanceName}</DialogTitle>
            <DialogContent>
                {instanceDetails ? (
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Typography variant="body1">
                            <strong>Status:</strong> {instanceDetails.status}
                        </Typography>
                        <Typography variant="body1">
                            <strong>ID:</strong> {instanceDetails.instanceId}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Webhook:</strong> {instanceDetails.integration.webhook_wa_business}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Proxy:</strong> {instanceDetails.hasProxy ? 'Ativo' : 'Inativo'}
                        </Typography>
                        {qrCode && (
                            <Box display="flex" justifyContent="center">
                                <Avatar
                                    variant="square"
                                    src={`data:image/png;base64,${qrCode}`}
                                    alt="QR Code"
                                    sx={{ width: 200, height: 200 }}
                                />
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Typography variant="body1">Carregando...</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)} color="primary" variant="contained">
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );

    const renderProxyModal = () => (
        <Dialog open={openProxyModal} onClose={() => setOpenProxyModal(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Configurar Proxy para {instance.instanceName}</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                        label="Host"
                        fullWidth
                        value={proxyConfig.host}
                        onChange={(e) => setProxyConfig({ ...proxyConfig, host: e.target.value })}
                    />
                    <TextField
                        label="Porta"
                        fullWidth
                        value={proxyConfig.port}
                        onChange={(e) => setProxyConfig({ ...proxyConfig, port: e.target.value })}
                    />
                    <TextField
                        label="Protocolo"
                        fullWidth
                        value={proxyConfig.protocol}
                        onChange={(e) => setProxyConfig({ ...proxyConfig, protocol: e.target.value })}
                        placeholder="http, https, etc."
                    />
                    <TextField
                        label="Usuário"
                        fullWidth
                        value={proxyConfig.username}
                        onChange={(e) => setProxyConfig({ ...proxyConfig, username: e.target.value })}
                    />
                    <TextField
                        label="Senha"
                        type="password"
                        fullWidth
                        value={proxyConfig.password}
                        onChange={(e) => setProxyConfig({ ...proxyConfig, password: e.target.value })}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSetProxy} color="primary" variant="contained">
                    Definir Proxy
                </Button>
                <Button onClick={() => setOpenProxyModal(false)} color="secondary" variant="outlined">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Card
                sx={{
                    borderRadius: 2,
                    backgroundColor: '#242424',
                    color: '#fff',
                    padding: 2,
                    marginBottom: 2,
                    boxShadow: 3,
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                        transform: 'scale(1.02)',
                        boxShadow: 6,
                    },
                }}
            >
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h5" sx={{ textTransform: 'capitalize', fontWeight: 600, color: '#e0e0e0' }}>
                                {instance.instanceName}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#999' }}>
                                {instance.instanceId}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}
                                gap={1}
                            >
                                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                    Status: {instance.status}
                                </Typography>
                                <Tooltip title={`Abrir WhatsApp de ${instance.instanceName}`}>
                                    <IconButton
                                        size="small"
                                        onClick={() => window.open(`https://wa.me/${instance.instanceId}`, '_blank')}
                                        color="success"
                                    >
                                        <WhatsAppIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Chip
                                label={instance.hasProxy ? 'Tem Proxy' : 'Sem Proxy'}
                                color={instance.hasProxy ? 'success' : 'error'}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <Tooltip title="Ver Detalhes">
                                <IconButton
                                    sx={{
                                        backgroundColor: '#3043ec',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#ff8c00',
                                        },
                                    }}
                                    onClick={handleViewDetails}
                                    size="large"
                                >
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={12} sm={9} display="flex" justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setOpenProxyModal(true)}
                                startIcon={<WhatsAppIcon />}
                            >
                                Configurar Proxy
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/* Modais */}
            {renderInstanceModal()}
            {renderProxyModal()}

            {/* Snackbar para Feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
});

// Componente InstanceList
const InstanceList = () => {
    const [instances, setInstances] = useState([]);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [newInstance, setNewInstance] = useState({
        number: '',
        proxy: {
            host: '',
            port: '',
            protocol: '',
            username: '',
            password: '',
        },
    });
    const [consultantName, setConsultantName] = useState('admin');
    const [qrCode, setQrCode] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    useEffect(() => {
        const fetchInstances = async () => {
            try {
                const response = await axios.get('https://zap.wh.tec.br/fetch-instances');
                setInstances(response.data);
            } catch (error) {
                console.error('Erro ao buscar instâncias:', error);
                setSnackbar({ open: true, message: 'Erro ao buscar instâncias.', severity: 'error' });
            }
        };

        // Obtendo o nome do consultor do TopMenu
        const consultant = localStorage.getItem('username');
        if (consultant) {
            setConsultantName(consultant);
        }

        fetchInstances();
    }, []);

    const handleCreateInstance = async () => {
        if (!newInstance.number) {
            setSnackbar({ open: true, message: 'Por favor, insira um número de WhatsApp.', severity: 'warning' });
            return;
        }

        const instanceName = `${consultantName}_${newInstance.number.slice(-4)}`;

        const config = {
            instanceName,
            number: newInstance.number,
            qrcode: true,
            mobile: false,
            integration: 'WHATSAPP-BAILEYS',
        };

        try {
            const response = await axios.post('https://zap.wh.tec.br/create-instance', config);
            console.log('Instância criada com sucesso:', response.data);

            if (response.data.hasOwnProperty('qrcode')) {
                setQrCode(response.data.qrcode.base64);
            }

            setInstances((prev) => [...prev, response.data]);
            setOpenCreateModal(false);
            setSnackbar({ open: true, message: 'Instância criada com sucesso.', severity: 'success' });
        } catch (error) {
            console.error('Erro ao criar a instância:', error);
            setSnackbar({ open: true, message: 'Erro ao criar a instância.', severity: 'error' });
        }
    };

    const renderCreateInstanceModal = () => (
        <Dialog open={openCreateModal} onClose={() => setOpenCreateModal(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Criar Nova Instância</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                        label="Número de WhatsApp"
                        fullWidth
                        value={newInstance.number}
                        onChange={(e) => setNewInstance({ ...newInstance, number: e.target.value })}
                        placeholder="Ex: 5511999998888"
                    />
                    <Typography variant="h6">Configuração do Proxy (Opcional)</Typography>
                    <TextField
                        label="Host"
                        fullWidth
                        value={newInstance.proxy.host}
                        onChange={(e) => setNewInstance({ ...newInstance, proxy: { ...newInstance.proxy, host: e.target.value } })}
                    />
                    <TextField
                        label="Porta"
                        fullWidth
                        value={newInstance.proxy.port}
                        onChange={(e) => setNewInstance({ ...newInstance, proxy: { ...newInstance.proxy, port: e.target.value } })}
                    />
                    <TextField
                        label="Protocolo"
                        fullWidth
                        value={newInstance.proxy.protocol}
                        onChange={(e) => setNewInstance({ ...newInstance, proxy: { ...newInstance.proxy, protocol: e.target.value } })}
                        placeholder="http, https, etc."
                    />
                    <TextField
                        label="Usuário"
                        fullWidth
                        value={newInstance.proxy.username}
                        onChange={(e) => setNewInstance({ ...newInstance, proxy: { ...newInstance.proxy, username: e.target.value } })}
                    />
                    <TextField
                        label="Senha"
                        type="password"
                        fullWidth
                        value={newInstance.proxy.password}
                        onChange={(e) => setNewInstance({ ...newInstance, proxy: { ...newInstance.proxy, password: e.target.value } })}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCreateInstance} color="primary" variant="contained">
                    Criar Instância
                </Button>
                <Button onClick={() => setOpenCreateModal(false)} color="secondary" variant="outlined">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <TopMenu />

            <Box display="flex" justifyContent="center" mt={4} mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenCreateModal(true)}
                    size="large"
                >
                    Adicionar Conta de WhatsApp
                </Button>
            </Box>

            {qrCode && (
                <Box display="flex" justifyContent="center" mb={4}>
                    <Avatar
                        variant="square"
                        src={`${qrCode}`}
                        alt="QR Code"
                        sx={{ width: 200, height: 200 }}
                    />
                </Box>
            )}

            <Grid container spacing={3} justifyContent="center">
                {instances.length > 0 ? (
                    instances.map((instance) => (
                        <Grid item xs={12} sm={6} md={4} key={instance.instanceId}>
                            <InstanceCard instance={instance} />
                        </Grid>
                    ))
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center" color="textSecondary">
                            Nenhuma instância encontrada. Adicione uma nova instância.
                        </Typography>
                    </Grid>
                )}
            </Grid>

            {/* Modal para criar nova instância */}
            {renderCreateInstanceModal()}

            {/* Snackbar para Feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default InstanceList;
